.header {
  padding: 0 20px;
  background-color: white;
  position: fixed;
  width: 100%;

  .setting {
    float: right;
  }

  .logo {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 60px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    background-color: white;
    color: deepskyblue;
    font-weight: bold;
    font-family: Helvetica, sans-serif;
  }

  .right {
    background-color: white;
    text-align: right;
  }
}

@primary-color: #1DA57A;